import { Answer, Field } from 'store/states/forms/types';
import { HistoricAnswer } from 'store/states/historics/types';

export function validateContainsRules(field: Field, answers: Answer[], type: 'all' | 'some') {
  const rules = type === 'all' ? field.rules?.needContainsAll : field.rules?.needContainsSome;
  let isValid = false;
  rules?.forEach((rule) => {
    const ruledAnswer = answers.find((answer) => answer.field_id === rule.field_id);
    isValid = rule?.value === ruledAnswer?.value;

    if (type === 'all' && !isValid) {
      return false;
    }
  });

  return isValid;
}

export function verifyRules(field: Field, answers: Answer[]) {
  if (field.rules !== undefined) {
    if (field.rules?.needContainsAll?.length > 0) {
      return validateContainsRules(field, answers, 'all');
    } if (field.rules?.needContainsSome?.length > 0) {
      return validateContainsRules(field, answers, 'some');
    }
  }
  return true;
}

export function validateHistoricContainsRules(field: Field, answers: HistoricAnswer[], type: 'all' | 'some') {
  const rules = type === 'all' ? field.rules?.needContainsAll : field.rules?.needContainsSome;
  let isValid = false;
  rules?.forEach((rule) => {
    const ruledAnswer = answers.find((answer) => answer.field_id === rule.field_id);
    isValid = rule?.value === ruledAnswer?.answer;

    if (type === 'all' && !isValid) {
      return false;
    }
  });

  return isValid;
}

export function verifyHistoricRules(field: Field, answers: HistoricAnswer[]) {
  if (field.rules !== undefined) {
    if (field.rules?.needContainsAll.length > 0) {
      return validateHistoricContainsRules(field, answers, 'all');
    } if (field.rules?.needContainsSome.length > 0) {
      return validateHistoricContainsRules(field, answers, 'some');
    }
  }
  return true;
}
